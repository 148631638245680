import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css"

import config from "../../../config"
import HeaderResources from "../../../components/header_resources"

const validationSchema = Yup.object().shape({
  EmploymentStatusRadio: Yup.string().required("This is a required field"),
  FirstName: Yup.string().required("This is a required field"),
  LastName: Yup.string().required("This is a required field"),
  EmailAddress: Yup.string()
    .email("Enter valid email address")
    .required("This is a required field"),
  IncomePlanLivingCostsEmployed: Yup.string().required("This is a required field"),
  IncomePlanTaxEfficientEmployed: Yup.string().required("This is a required field"),
  IncomePlanChildrensEducationEmployed: Yup.string().required("This is a required field"),
  IncomePlanSurplusIncomeEmployed: Yup.string().required("This is a required field"),
  IncomePlanIncomeEmploymentEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
  InvestmentPlanWealthPlanEmployed: Yup.string().required("This is a required field"),
  InvestmentPlanInvestmentStructureEmployed: Yup.string().required("This is a required field"),
  InvestmentPlanTradeOffEmployed: Yup.string().required("This is a required field"),
  InvestmentPlanAchievingGoalsEmployed: Yup.string().required("This is a required field"),
  InvestmentPlanSleepNightEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
  DebtPlanDebtStructureEmployed: Yup.string().required("This is a required field"),
  DebtPlanPersonalInvestmentDebtEmployed: Yup.string().required("This is a required field"),
  DebtPlanEliminateDebtEmployed: Yup.string().required("This is a required field"),
  DebtPlanOverallDebtEmployed: Yup.string().required("This is a required field"),
  DebtPlanDebtPlanEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
  RiskPlanInsuranceCoverEmployed: Yup.string().required("This is a required field"),
  RiskPlanPayingPremiumsEmployed: Yup.string().required("This is a required field"),
  RiskPlanAppropriateCoverEmployed: Yup.string().required("This is a required field"),
  RiskPlanFamilyLiveEmployed: Yup.string().required("This is a required field"),
  RiskPlanInsurancePoliciesEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
  RitrementPlanRetirementGoalsEmployed: Yup.string().required("This is a required field"),
  RitrementPlanRetirementIncomeEmployed: Yup.string().required("This is a required field"),
  RitrementPlanRequiredIncomeEmployed: Yup.string().required("This is a required field"),
  RitrementPlanRetirementFundEmployed: Yup.string().required("This is a required field"),
  RitrementPlanRetirementStrategyEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
  EstatePlanMyWillEmployed: Yup.string().required("This is a required field"),
  EstatePlanMyAssetsEmployed: Yup.string().required("This is a required field"),
  EstatePlanFinancialDecisionsEmployed: Yup.string().required("This is a required field"),
  EstatePlanMyExecutorEmployed: Yup.string().required("This is a required field"),
  EstatePlanMyWishesEmployed: Yup.string().when("EmploymentStatusRadio", {
    is: "Employed",
    then: Yup.string().required("This is a required field"),
  }),
})

function getQuestionAnswerOptions(radioName, handleChange, handleBlur, isEmployee = true) {
  return (
    <div class="w-100 d-inlineflex flex-column">
      <div class="w-100 d-flex flex-row">
        <div class="w-20">
          <div class="radio radio-primary mb-1">
            <input
              name={radioName}
              id={isEmployee ? radioName + "One" : radioName + "RetirementOne"}
              type="radio"
              value={1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor={isEmployee ? radioName + "One" : radioName + "RetirementOne"}>1</label>
          </div>
        </div>
        <div class="w-20">
          <div class="radio radio-primary mb-1">
            <input
              name={radioName}
              id={isEmployee ? radioName + "Two" : radioName + "RetirementTwo"}
              type="radio"
              value={2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor={isEmployee ? radioName + "Two" : radioName + "RetirementTwo"}>2</label>
          </div>
        </div>
        <div class="w-20">
          <div class="radio radio-primary mb-1">
            <input
              name={radioName}
              id={isEmployee ? radioName + "Three" : radioName + "RetirementThree"}
              type="radio"
              value={3}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor={isEmployee ? radioName + "Three" : radioName + "RetirementThree"}>
              3
            </label>
          </div>
        </div>
        <div class="w-20">
          <div class="radio radio-primary mb-1">
            <input
              name={radioName}
              id={isEmployee ? radioName + "Four" : radioName + "RetirementFour"}
              type="radio"
              value={4}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor={isEmployee ? radioName + "Four" : radioName + "RetirementFour"}>
              4
            </label>
          </div>
        </div>
      </div>

      <ErrorMessage name={radioName} component="span" className="help-block" />
    </div>
  )
}

export default function FiveMinuteFinancial() {
  const heroTitle = "5 Minute Financial Health Check"

  const { executeRecaptcha } = useGoogleReCaptcha()
  // const [showSuccessDiv, setShowSuccessDiv] = useState(false)
  const showSuccessDiv = false
  const [errorMessage, setErrorMessage] = useState("")

  const initStateObj = {
    EmploymentStatusRadio: "",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    IncomePlanLivingCostsEmployed: "",
    IncomePlanTaxEfficientEmployed: "",
    IncomePlanChildrensEducationEmployed: "",
    IncomePlanSurplusIncomeEmployed: "",
    IncomePlanIncomeEmploymentEmployed: "",
    InvestmentPlanWealthPlanEmployed: "",
    InvestmentPlanInvestmentStructureEmployed: "",
    InvestmentPlanTradeOffEmployed: "",
    InvestmentPlanAchievingGoalsEmployed: "",
    InvestmentPlanSleepNightEmployed: "",
    DebtPlanDebtStructureEmployed: "",
    DebtPlanPersonalInvestmentDebtEmployed: "",
    DebtPlanEliminateDebtEmployed: "",
    DebtPlanOverallDebtEmployed: "",
    DebtPlanDebtPlanEmployed: "",
    RiskPlanInsuranceCoverEmployed: "",
    RiskPlanPayingPremiumsEmployed: "",
    RiskPlanAppropriateCoverEmployed: "",
    RiskPlanFamilyLiveEmployed: "",
    RiskPlanInsurancePoliciesEmployed: "",
    RitrementPlanRetirementGoalsEmployed: "",
    RitrementPlanRetirementIncomeEmployed: "",
    RitrementPlanRequiredIncomeEmployed: "",
    RitrementPlanRetirementFundEmployed: "",
    RitrementPlanRetirementStrategyEmployed: "",
    EstatePlanMyWillEmployed: "",
    EstatePlanMyAssetsEmployed: "",
    EstatePlanFinancialDecisionsEmployed: "",
    EstatePlanMyExecutorEmployed: "",
    EstatePlanMyWishesEmployed: "",
  }

  const formSubmitHandler = async (values, resetFormHandler) => {
    if (!executeRecaptcha) {
      return
    }
    const captchaResponse = await executeRecaptcha("5_minute_health_check")

    var formObject = document.forms.healthcheckform

    var bodyFormData = new FormData(formObject)

    bodyFormData.append("g-recaptcha-response", captchaResponse)

    try {
      const healthcheckApiResponse = await axios({
        method: "post",
        url: config.API_BASE_URL + "heath-checkup-form-submit.php",
        data: bodyFormData,
      })

      if (healthcheckApiResponse.data.success) {
        //setShowSuccessDiv(true)
        resetFormHandler()
        /*setTimeout(() => {
          setShowSuccessDiv(false)
        }, 8000)*/
        navigate("/resources/tools/thankyou", {
          state: {
            form: "5-minute-health-check",
          },
        })
      } else {
        setErrorMessage(healthcheckApiResponse.data.errors)
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="innerpage--content innerpage--content-sm pb-0">
        <div class="w-100 mb-5 sm:mmb-5">
          <Link class="back--home" to="/resources/tools">
            <i class="fas fa-chevron-left"></i> <span>Back to Tools</span>
          </Link>
        </div>

        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <div id="hlExcelRenderArea" class="form-horizontal form-fix-bottom-padding ___fmhc">
          <div class="container-fluid p-0">
            <div class=" form-control-static">
              <h3 class="h5 h5-sm">
                5 Minute Financial Health Check (Self Assessment Questionnaire)
              </h3>
            </div>

            <Formik
              initialValues={initStateObj}
              onSubmit={(values, { resetForm }) => {
                formSubmitHandler(values, resetForm)
              }}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <form
                  id="healthcheckform"
                  class="form-horizontal mt-4"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div class="row form-group">
                    <div class="col-12 col-md-8 col-sm-8 col-xs-12">
                      <label htmlFor="EmploymentStatus">
                        Employment status <span class="required">*</span>
                      </label>
                      <div class="w-100 d-inlineflex flex-column mt-3 sm:mmt-0">
                        <div className="w-100 d-flex flex-row">
                          <div class="w-50">
                            <div class="radio radio-primary mb-1">
                              <input
                                name="EmploymentStatusRadio"
                                id="Employed"
                                type="radio"
                                value="Employed"
                                onChange={() => {
                                  setFieldValue("EmploymentStatusRadio", "Employed")
                                  setFieldValue("FirstName", "")
                                  setFieldValue("LastName", "")
                                  setFieldValue("EmailAddress", "")
                                }}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="Employed"> Employed/Working</label>
                            </div>
                          </div>
                          <div class="w-50">
                            <div class="radio radio-primary mb-1">
                              <input
                                name="EmploymentStatusRadio"
                                id="Retired"
                                type="radio"
                                value="Retired"
                                onChange={() => {
                                  setFieldValue("EmploymentStatusRadio", "Retired")
                                  setFieldValue("FirstName", "")
                                  setFieldValue("LastName", "")
                                  setFieldValue("EmailAddress", "")
                                }}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="Retired">Retired</label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="EmploymentStatusRadio"
                          component="span"
                          className="help-block"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class={`panel-group panel-group--forms EmploymentStatusEmployed ${
                      values.EmploymentStatusRadio ? `` : `d-none`
                    }`}
                  >
                    <div class="row form-group">
                      <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="FirstName">
                          First Name <span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="FirstName"
                          value={values.FirstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="FirstName" component="span" className="help-block" />
                      </div>
                      <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="LastName">
                          Last Name <span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="LastName"
                          id="HealthCheckupLastName"
                          value={values.LastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="LastName" component="span" className="help-block" />
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="EmailAddress">
                          Email <span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="EmailAddress"
                          id="HealthCheckupEmail"
                          value={values.EmailAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="EmailAddress" component="span" className="help-block" />
                      </div>
                    </div>
                  </div>

                  <div
                    class={`panel-group panel-group--forms EmploymentStatusEmployed ${
                      values.EmploymentStatusRadio === "Employed" ? `` : `d-none`
                    }`}
                    id="accordion"
                  >
                    <Accordion allowMultipleExpanded={true}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>1. My Income Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My current income meets my living costs
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanLivingCostsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. My income is well structured and tax efficient
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanTaxEfficientEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. My children's education costs are covered
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanChildrensEducationEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My surplus income is being invested efficiently for wealth
                                creation
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanSurplusIncomeEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">5. My income and employment are secure</legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanIncomeEmploymentEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>2. My Investment Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. I have an appropriate 5 year wealth plan
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanWealthPlanEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I have a tax efficient investment structure
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanInvestmentStructureEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I understand the risk/reward trade-off between my investment
                                strategy and other options
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanTradeOffEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My investments are achieving my goals
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanAchievingGoalsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                5. I can sleep at night with the investments I have in place
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanSleepNightEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>3. My Debt Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My debt is structured in the best possible way
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanDebtStructureEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I have the right mix of personal and investment debt
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanPersonalInvestmentDebtEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I have a strategy to eliminate my personal debt in the shortest
                                possible time
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanEliminateDebtEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My overall debt levels are appropriate for my current life status
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanOverallDebtEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">5. My debt plan is regularly reviewed</legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanDebtPlanEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>4. My Risk Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My insurances cover me comprehensively for most foreseeable
                                circumstances
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanInsuranceCoverEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I am paying the most competitive premiums
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanPayingPremiumsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I have the appropriate levels of cover in place
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanAppropriateCoverEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My family would continue to live comfortably if something
                                happened to me
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanFamilyLiveEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                5. My insurance policies are regularly reviewed
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanInsurancePoliciesEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>5. My Retirement Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. I have a clear understanding of my retirement goals
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementGoalsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I have a clear understanding of my desired retirement income
                                needs
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementIncomeEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I am on track to achieve my required income in retirement
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRequiredIncomeEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My retirement funds will last throughout my retirement
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementFundEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                5. My retirement funding strategy is structured tax efficiently
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementStrategyEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>6. My Estate Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My Will is right up to date and reflects my current wishes
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyWillEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. My assets will be transferred to all the right people, at the
                                right time tax efficiently
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyAssetsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I have someone that is able to make financial decisions on my
                                behalf if I am unable to do so
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanFinancialDecisionsEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My surviving family or executor would easily find all relevant
                                documentation
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyExecutorEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                5. My family is aware of my medical and lifestyle wishes
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyWishesEmployed",
                                handleChange,
                                handleBlur
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  <div
                    class={`panel-group panel-group--forms EmploymentStatusRetired ${
                      values.EmploymentStatusRadio === "Retired" ? `` : `d-none`
                    }`}
                    id="accordionRetired"
                  >
                    <Accordion allowMultipleExpanded={true}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            1. Is free will real or just an illusion?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My current income meets my living costs
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanLivingCostsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. My income is well structured and tax efficient
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanTaxEfficientEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. My income will last throughout my retirement
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanChildrensEducationEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. I am receiving all my government pension entitlements
                              </legend>
                              {getQuestionAnswerOptions(
                                "IncomePlanSurplusIncomeEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>2. My Investment Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. I have a tax efficient investment structure
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanWealthPlanEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I understand the risk/reward trade-off between my investment
                                strategy and other options
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanInvestmentStructureEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. My investments are achieving my goals
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanTradeOffEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. I can sleep at night with the investments I have in place
                              </legend>
                              {getQuestionAnswerOptions(
                                "InvestmentPlanAchievingGoalsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>3. My Debt Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My current level of debt is appropriate
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanDebtStructureEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I have a plan to eliminate any outstanding debt
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanPersonalInvestmentDebtEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I understand the implications of gifting or lending money to my
                                children
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanEliminateDebtEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. I understand how debt could be used if my retirement funds run
                                out
                              </legend>
                              {getQuestionAnswerOptions(
                                "DebtPlanOverallDebtEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>4. My Risk Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. If my children had a serious illness or injury they would not
                                require my financial assistance
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanInsuranceCoverEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. I understand my options with regards to Aged Care
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanPayingPremiumsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I understand the implications of being a guarantor for my
                                children's debt
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanAppropriateCoverEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. On my death I have accounted for my children's personal and
                                financial circumstances
                              </legend>
                              {getQuestionAnswerOptions(
                                "RiskPlanFamilyLiveEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>5. My Retirement Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. I am living the retirement I envisaged
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementGoalsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. That my ongoing retirement goals will continue to be met
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementIncomeEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. I understand my options if my retirement funds run out
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRequiredIncomeEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. I understand the impact of a one off expenditure on my retirement
                                funds
                              </legend>
                              {getQuestionAnswerOptions(
                                "RitrementPlanRetirementFundEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>6. My Estate Plan</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="alert alert-info mb-3">
                            <span class="css-1ordfia">1</span> being lowest and{" "}
                            <span class="css-1ordfia">4</span> being highest.
                          </div>

                          <h4 class="h5 h5-sm mb-1">How confident am I that...</h4>
                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                1. My Will is right up to date and reflects my current wishes
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyWillEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                2. My surviving family or executor would easily find all relevant
                                documentation
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyAssetsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                3. Someone is able to make financial, medical & lifestyle decisions
                                on my behalf if I am unable
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanFinancialDecisionsEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>

                          <div class="form-group">
                            <fieldset class="form-item field radio required" aria-required="true">
                              <legend class="title">
                                4. My estate assets will be transferred to all the right people at
                                the right time tax efficiently
                              </legend>
                              {getQuestionAnswerOptions(
                                "EstatePlanMyExecutorEmployed",
                                handleChange,
                                handleBlur,
                                false
                              )}
                            </fieldset>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  <div class="hr">
                    <hr />
                  </div>

                  <p class="mb-2 small">
                    Please ensure you complete the 'compulsory fields' and answer all the questions.
                  </p>
                  <button
                    type="submit"
                    class="c-button c-button--blue c-button-radius c-button-inline c-button-lg"
                  >
                    Submit
                  </button>

                  {showSuccessDiv && (
                    <div class="alert alert-success mt-3" role="alert">
                      <strong>Thanks for the response. We'll get back to you.</strong>
                    </div>
                  )}

                  {errorMessage && (
                    <div class="alert alert-danger mt-3" role="alert">
                      <strong>{errorMessage}</strong>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </HeaderResources>
  )
}
